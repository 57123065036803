@import '../../assets/scss/mixins';
@import '../../assets/scss/variables';

.dropdownContainer {
    width: 100%;
    position: relative;
    display: inline-block;

    .dropdownBody {
        .dropdownHeader {
            min-width: 105px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 6px;
            border-bottom: 1px solid $primaryColor;
            cursor: pointer;

            >span {
                color: $black;

                &.default {
                    color: $inputPlaceholderColor;
                }

                @include sm {
                    font-size: 14px;
                }
            }

            &.dropdownClose {
                >svg {
                    rotate: 180deg;
                    transition: all 0.2s ease-in;
                }
            }

            &.dropdownOpen {
                >svg {
                    rotate: 0deg;
                    transition: all 0.2s ease-in;
                }
            }

            >span {
                margin-right: 10px;
            }

            @include sm {
                min-width: unset;
            }
        }

        .dropdownOptions {
            position: absolute;
            top: 100%;
            left: 0;
            background-color: $white;
            border: 1px solid $primaryColor;
            border-radius: 0 0 4px 4px;
            border-top: none;
            max-height: 220px;
            overflow-y: auto;
            width: 100%;
            z-index: 12;
            animation: growDown 300ms ease-in-out forwards;
            transform-origin: top center;

            .inputField {
                width: 100%;
                position: sticky;
                top: 0;
                border: none;
                border-bottom: 1px solid $primaryColor;
                padding: 4px;
            }

            .dropdownOption {
                padding: 4px 6px;
                cursor: pointer;

                &:first-of-type {
                    margin-top: 6px;
                }

                &.active {
                    color: $white;
                    background-color: $primaryColor;
                }

                &:hover {
                    background-color: $primaryColor;
                }
            }
        }
    }
}

@keyframes growDown {
    0% {
        transform: scaleY(0)
    }
}
@import '../../assets/scss/mixins';
@import '../../assets/scss/variables';

.contactUsPage {
    padding: 40px 20px;

    .title {
        font-size: 38px;
        font-style: normal;
        font-weight: 700;
        line-height: 55px;

        @include sm {
            font-size: 32px;
        }

        @include xs {
            font-size: 30px;
            text-align: center;
        }
    }

    .subTitle {
        font-size: 14px;
        color: $darkGrey;
        margin-top: 8px;
        padding-left: 64px;

        @include sm {
            font-size: 13px;
            padding-left: 0;
        }
    }

    .contactSection {
        .contactChannels {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 28px;
            margin-top: 64px;

            .channelOptions {
                position: relative;
                display: flex;
                background: $lightGray;
                padding: 40px 30px;
                padding-right: 8px;

                .icon {
                    position: absolute;
                    top: -11px;
                    transform: translate(-50%, -50%);
                    left: 50%;
                    border-radius: 50%;
                    width: 65px;
                    height: 65px;
                    background: $primaryColor;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 4px;
                    margin-right: 10px;
                    margin-top: 6px;

                    @include md {
                        width: 55px;
                        height: 55px;
                    }
                }

                .channelDesc {
                    >p {
                        color: $primaryColor;
                        font-size: 20px;
                        font-weight: 700;
                        margin-bottom: 10px;

                        @include lg {
                            font-size: 18px;
                        }
                    }

                    >div {
                        max-width: 300px;
                        color: $darkGrey;
                        font-size: 13px;
                        font-weight: 600;

                        .staticTitle {
                            font-weight: 700;
                        }

                        @include md {
                            font-size: 11px;
                        }
                    }
                }

                @include md {
                    align-items: center;
                }
            }

            .channelFolowOptions {
                position: relative;
                display: grid;
                grid-gap: 10px;
                background: $lightGray;
                padding: 40px 30px;
                align-items: center;

                .icon {
                    position: absolute;
                    top: -11px;
                    transform: translate(-50%, -50%);
                    left: 50%;
                    border-radius: 50%;
                    width: 65px;
                    height: 65px;
                    background: $primaryColor;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 4px;
                    margin-right: 10px;
                    margin-top: 6px;

                    >svg {
                        position: relative;
                        left: 3px;
                        top: 3px;
                    }

                    @include md {
                        width: 55px;
                        height: 55px;
                    }
                }

                .channelTitle {
                    color: $primaryColor;

                    font-size: 22px;
                    font-weight: 700;
                    margin-bottom: 4px;

                    @include lg {
                        font-size: 18px;
                    }
                }

                .socialMediaFlex {
                    width: 100%;
                    display: flex;
                    align-items: center;

                    .itemMedia {
                        img {
                            width: 35px;
                            height: 35px;
                            object-fit: cover;
                        }

                        &:not(:last-child) {
                            margin-right: 10px;
                        }
                    }
                }

                .socialMediaGrid {
                    width: 100%;
                    display: grid;
                    grid-template-columns: repeat(auto-fill, minmax(30px, 1fr));
                    grid-gap: 10px;
                    align-items: center;
                }
            }

            @include md {
                grid-gap: 20px;
            }

            @include sm {
                grid-template-columns: 1fr;
            }
        }

        .formData {
            margin-top: 80px;

            >h3 {
                color: $darkGrey;
                font-size: 20px;
                font-weight: 700;
                margin-bottom: 4px;
            }

            .form {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 40px;
                margin-top: 20px;

                .inputField {
                    width: 100%;

                    >p {
                        color: $black;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 22px;

                        .required {
                            color: $errorColor;
                        }
                    }

                    .error {
                        font-size: 12px;
                        color: $errorColor;
                        padding-top: 3px;
                        display: flex;
                        justify-content: flex-end;
                    }

                    &.fullWidth {
                        grid-column: 1/3;

                        @include sm {
                            grid-column: 1/2;
                        }
                    }

                    .textareaLabel {
                        display: flex;
                        align-items: center;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;

                        >span {
                            margin-left: 4px;
                            color: $errorColor;
                        }
                    }

                    >textarea {
                        resize: none;
                        width: 100%;
                        min-height: 90px;
                        padding: 6px 10px;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        border: none;
                        border-bottom: 1px solid $primaryColor;
                    }
                }

                @include sm {
                    grid-template-columns: 1fr;
                }
            }

            .sendBtn {
                margin-top: 28px;
                display: flex;
                justify-content: flex-end;

                @include xs {
                    >button {
                        width: 100%;
                    }
                }
            }
        }
    }

    @include lg {
        padding: 20px;
    }
}
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/variables";

.chartsContainer {
  width: 100%;
  padding: 0px 0px 0px 30px;
  display: grid;

  >div {
     >h2 {
      margin-bottom: 30px;
      color: $darkGrey;
     }

     >div {
      @include md {
        max-width: 300px; 
        margin: 0 auto; 
      }
     }
  }
}
@import "../../assets/scss/mixins";
@import "../../assets/scss/variables";

.profilePage {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 40px;

    .otpContainer {
        max-width: 600px;
        min-height: 400px;
        width: 100%;
        border: 1px solid $secondaryColor;
        padding: 20px;
        margin-top: 20px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        grid-gap: 15px;

        .nextBtn {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-top: 28px;

            &.btnStyle {
                justify-content: space-between;
            }
        }
    }

    .mainInfo {
        padding: 30px;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        >div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            grid-gap: 10px;

            .imageContainer {
                width: 160px;
                height: 140px;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }
        }
    }

    .profileSettings {
        padding: 10px 30px;
        border-left: 1px solid $borderColor;

        .profileSettingsForm {
            margin-top: 50px;

            .sectionContent {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 40px;
                align-items: center;

                .inputField {
                    position: relative;
                    width: 100%;

                    >div {
                        width: 100%;
                    }

                    .dateTitle {
                        display: flex;
                        align-items: center;
                        color: $black;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 29px;

                        .required {
                            color: $errorColor;
                        }
                    }

                    .error {
                        position: absolute;
                        bottom: -24px;
                        color: $errorColor;
                        padding-top: 4px;
                        font-size: 12px;
                        height: 20px;
                    }

                    .birthDate {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        grid-gap: 60px;
                        margin-top: 10px;
                    }

                    .checkboxTitle {
                        display: flex;
                        align-items: center;
                        color: $black;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;

                        .required {
                            color: $errorColor;
                            margin-left: 4px;
                        }
                    }

                    .checkboxField {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        padding: 10px;
                    }
                }


                @include xs {
                    grid-template-columns: 1fr;
                }
            }

            .cardDetails {
                >p {
                    margin: 20px 0px 20px 0px;
                    font-size: 18px;
                }

                .martialStatus {
                    margin-top: 20px;
                }
            }

            .inputRadioField {
                width: 100%;

                .radioTitle {
                    display: flex;
                    align-items: center;
                    color: $black;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;

                    .required {
                        color: $errorColor;
                        margin-left: 4px;
                    }
                }

                .radioField {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    padding: 10px;
                }
            }

            .multipleContribution {
                >div {
                    display: flex;

                    .text {
                        margin-top: 18px;
                        color: $darkGrey;
                    }
                }
            }

            .upload {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 28px 0;

                .uploadFile {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }

                >input {
                    display: none;
                }

                .upladedFiles {
                    position: relative;

                    img {
                        max-width: 180px;
                        max-height: 80px;
                        object-fit: cover;
                    }

                    .file {
                        .removeFile {
                            position: absolute;
                            right: -10px;
                            top: -10px;
                            color: #EC1C2B;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
        .fullWidth {
            grid-column: 1/3;
    
            @include xs {
                grid-column: 1/2;
            }
        }
        }
    
        @include lg {
            .profilePage {
                flex-direction: column;
                align-items: center;
                padding-top: 0px;
    
                .mainInfo {
                    margin-bottom: 20px;
                }

                .profileSettings {
                    border-left: none;
                    border-top: 1px solid $lightGray;
                }
            }
        }

        @include sm {
            .profilePage {
                .profileSettings {
                    >h1 {
                        font-size: 25px;
                    }
                }
            }
        }
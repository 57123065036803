.uploadBtn {
  width: 182px;
  height: 182px;
  flex-shrink: 0;
  border: 1px solid #16524d;
  border-radius: 5px;
  border-style: dashed;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ant-upload,
.ant-upload-select {
  width: 100% !important;
  height: 182px !important;
}

.imgPreview {
  width: 182px;
  height: 182px;
  object-fit: cover;
  border: 2px solid #16524d;
  border-radius: 10px;
  cursor: pointer;
}

@import '../../../assets/scss/mixins';
@import '../../../assets/scss/variables';

.aboutUsSection {
    width: 100%;

    .title {
        color: $darkGrey;
        font-size: 38px;
        font-style: normal;
        font-weight: 600;
        line-height: 50px;
        margin-bottom: 30px;

        @include sm {
            text-align: center;
            font-size: 30px;
            margin-bottom: 20px;
        }

        @include xs {
            font-size: 22px;
        }
    }

    .textEditorContent {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;

        .englishVersion {
            display: grid;
            grid-gap: 20px;
        }

        .arabicVersion {
            display: grid;
            grid-gap: 20px;
        }


        .ckEditor {
            .editorTitle {
                margin-bottom: 10px;
                color: $darkGrey;
                font-weight: 600;
                line-height: 24px;
            }
        }

        @include lg {
            grid-template-columns: 1fr;
        }
    }

    .editorBtn {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;

        @include xs {
            >button {
                width: 100%;
            }
        }
    }
}
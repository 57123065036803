@import '../../assets/scss/mixins';
@import '../../assets/scss/variables';

.radioContainer {
    display: flex;
    align-items: center;
    gap: 24px;

    .radioButton {
        display: inline-block;
        position: relative;

        .radioButtonInput {
            position: absolute;
            opacity: 0;
            left: 0;
            top: 0;
            z-index: 12;
            width: 18px;
            height: 18px;
            cursor: pointer;

            &.adminRadioButton {
                &:checked {
                    +.radioButtonLabel .radioButtonCustom {
                        transform: translateY(-50%) scale(0.9);
                        border: 5px solid $adminColor;
                        color: $adminColor;
                    }
                }
            }

            &:checked {
                +.radioButtonLabel .radioButtonCustom {
                    transform: translateY(-50%) scale(0.9);
                    border: 5px solid $primaryColor;
                    color: $primaryColor;
                }
            }

        }

        .radioButtonLabel {
            display: inline-block;
            padding-left: 30px;
            margin-bottom: 10px;
            position: relative;
            font-size: 16px;
            color: $inputPlaceholderColor;
            cursor: pointer;
            transition: all 0.3s cubic-bezier(0.23, 1, 0.320, 1);
        }

        .radioButtonCustom {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 2px solid #555;
            transition: all 0.3s cubic-bezier(0.23, 1, 0.320, 1);
        }
    }
}
@import '../../../assets/scss/mixins';
@import '../../../assets/scss/variables';

.contactUsSection {
    width: 100%;

    .title {
        color: $darkGrey;
        font-size: 38px;
        font-style: normal;
        font-weight: 600;
        line-height: 50px;
        margin-bottom: 30px;

        @include sm {
            text-align: center;
            font-size: 30px;
        }

        @include xs {
            font-size: 22px;
        }
    }

    .contactUsContent {
        .mainFields {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 40px;
            align-items: center;

            @include sm {
                grid-template-columns: 1fr;
            }
        }

        .addFields {
            position: relative;
            margin-top: 20px;

            .addBtn {
                cursor: pointer;

                color: $darkGrey;
                font-size: 36px;
                max-width: 20px;

                &:hover {
                    +.hoverText {
                        display: block;
                    }
                }
            }

            .hoverText {
                display: none;
                position: absolute;
                top: 0;
                left: 28px;

                font-size: 14px;
                color: $darkGrey;
            }
        }

        .additionalFields {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(460px, 1fr));
            grid-gap: 28px;

            .inputFieldContent {
                position: relative;

                .removeBtn {
                    position: absolute;
                    top: 24px;
                    right: 6px;
                    padding: 8px;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    font-size: 16px;
                    font-weight: 700;
                    cursor: pointer;
                }

                .inputField {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-gap: 40px;
                    padding: 16px;
                    background-color: $secondaryBgColor;
                    border-radius: 8px;
                    margin-top: 20px;

                    .upload {
                        width: 100%;
                        grid-column: 1/3;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .uploadContent {
                            display: flex;
                            align-items: center;

                            .uploadIcon {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                cursor: pointer;

                                >p {

                                    font-size: 14px;
                                    color: $darkGrey;
                                    margin-top: 4px;
                                }
                            }

                            .socialImage {
                                margin-left: 20px;

                                .image {
                                    position: relative;

                                    .removeImg {
                                        position: absolute;
                                        right: 48px;
                                        top: 0;
                                        color: $primaryColor;
                                        cursor: pointer;
                                    }

                                    >img {
                                        max-width: 180px;
                                        max-height: 80px;
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                    }
                                }
                            }
                        }

                        >input {
                            display: none;
                        }

                        @include sm {
                            grid-column: 1/2;
                        }
                    }

                    @include sm {
                        grid-template-columns: 1fr;
                    }
                }
            }

            @include lg {
                grid-template-columns: repeat(auto-fit, minmax(490px, 1fr));
            }

            @include sm {
                grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
            }

            @include xs {
                grid-template-columns: repeat(auto-fit, minmax(277px, 1fr));
            }
        }

        .saveBtn {
            margin-top: 20px;
            display: flex;
            justify-content: flex-end;
        }
    }
}
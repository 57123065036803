@import "../../assets/scss/mixins";
@import "../../assets/scss/variables";

.mainPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    // margin-top: 20px;

    // @include lg {
    //     margin-top: 95px;
    // }
}
@import '../../../assets/scss/mixins';
@import '../../../assets/scss/variables';

.mediaDetails {
    padding: 40px 20px;

    >h3 {
        color: $white;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 12px;
        background-color: $primaryColor;
        padding: 16px;
    }

    .subTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        >div {
            display: flex;
            align-items: center;

            >span {
                font-weight: 600;
                color: $darkGrey;
                font-size: 12px;

                &.shareBtn {
                    cursor: pointer;
                }
            }

            >svg {
                margin-right: 4px;
            }
        }

        @include sm {
            padding: 0 10px;
        }
    }

    .thumbnailImage {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px;
        border-radius: 8px;
        border: 1px solid $secondaryColor;

        img {
            width: 100%;
            max-height: 600px;
            object-fit: cover;
        }
    }

    .desc {
        color: $darkGrey;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        margin-top: 28px;

        @include sm {
            font-size: 16px;
            padding: 0 10px;
            margin-top: 10px;
        }
    }
}
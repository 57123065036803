@import "../../../../assets/scss/variables";

.modalBody {
    .inputRadioField {
        width: 100%;

        .radioTitle {
            display: flex;
            align-items: center;
            color: $black;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            .required {
                color: $errorColor;
                margin-left: 4px;
            }
        }

        .radioField {
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding: 10px;
        }
    }
}
@import '../../assets/scss/mixins';
@import '../../assets/scss/mixins';

.eventNewsCard {
    background: $secondaryBgColor;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    .cardThumbnail {
        position: relative;

        img {
            width: 100%;
            height: 220px;
            object-fit: cover;
        }

        .cardDate {
            width: 100%;
            padding: 10px;
            position: absolute;
            bottom: 0;
            left: 0;
            color: $white;
            font-weight: 600;
            background-color: rgba(33, 43, 54, 0.8);
        }
    }

    .cardBody {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 216px;
        padding: 20px 10px;

        .bodyData {
            >h3 {
                color: $darkGrey;
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 33px;
                margin-bottom: 10px;

                @include sm {
                    font-size: 18px;
                    line-height: 28px;
                }
            }

            .desc {
                color: $darkGrey;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 26px;

                @include sm {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }

        .btnMore {
            margin-top: 10px;
            color: $primaryColor;
            font-weight: 600;
            cursor: pointer;
        }

        @include sm {
            padding: 10px;
            min-height: 166px;
        }
    }
}
@import '../../assets/scss/mixins';
@import '../../assets/scss/variables';

.mediaPage {
    width: 100%;
    padding-top: 40px;

    .tabs {
        display: flex;
        align-items: center;
        justify-content: center;

        @include sm {
            border-bottom: 1px solid $darkGrey;
        }
    }

    .tabContent {
        margin-top: 40px;
        background-color: $secondaryBgColor;
        padding: 20px;

        .events {
            display: grid;
            grid-gap: 40px;
            grid-template-columns: repeat(2, 1fr);

            @include lg {
                grid-gap: 24px;
            }

            @include sm {
                grid-template-columns: 1fr;
            }
        }

        .pagination {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
        }

        .news {
            display: grid;
            grid-gap: 24px;
            grid-template-columns: repeat(3, 1fr);

            @include md {
                grid-template-columns: repeat(2, 1fr);
            }

            @include sm {
                grid-template-columns: 1fr;
            }
        }
    }
}
@import '../../assets/scss/mixins';
@import '../../assets/scss/variables';

.accordion {
    border-bottom: 1px solid black;
    overflow: hidden;

    .questionContainer {
        width: 100%;
        text-align: left;
        padding: 20px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 500;
        font-size: 20px;
        background: transparent;
        border: none;
        cursor: pointer;

        &.active {
            color: $primaryColor;
            background-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.04), transparent);
        }

        &:hover {
            background-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.04), transparent);
        }

        .arrow {
            >svg {
                rotate: 180deg;
                transition: all .4s ease-in;
            }

            &.rotate {
                >svg {
                    rotate: 0deg;
                    transition: all .4s ease-in;
                }
            }
        }
    }

    .answerContainer {
        padding: 0 1rem;
        transition: height .4s ease-in-out;

        .answerContent {
            padding: 1rem 0;
            font-size: 16px;

            .splitAnswer {
                &:not(:last-child) {
                    margin-bottom: 24px;
                }
            }

            .arrayData {
                &:not(:last-child) {
                    margin-bottom: 20px;
                }

                .arrayDataItem {
                    display: flex;
                    height: 100%;
                    padding-bottom: 20px;

                    .icon {
                        margin-right: 20px;
                        display: flex;
                        align-items: center;
                    }

                    .itemVision {
                        >p {
                            font-size: 16px;
                            color: $darkGrey;
                            font-weight: 900;
                            text-transform: uppercase;
                            margin-bottom: 10px;
                        }

                        >div {
                            font-size: 14px;
                            line-height: 28px;
                        }
                    }

                }
            }
        }
    }
}
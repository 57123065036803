@import '../../assets/scss/mixins';
@import '../../assets/scss/variables';

.aboutUsPage {
    width: 100%;
    padding: 100px 20px 40px;

    .header {
        font-size: 32px;
        font-weight: 700;
        line-height: normal;
    }

    .body {
        margin-top: 40px;
        display: grid;
        grid-template-columns: 220px 1fr;
        grid-gap: 32px;

        .aboutUsLinks {
            >div {
                border-left: 1px solid $darkGrey;

                >p {
                    font-size: 16px;
                    padding: 20px;
                    cursor: pointer;
                    border-left: 3px solid transparent;

                    &.active {
                        border-left: 3px solid $primaryColor;
                        color: $primaryColor;
                        font-weight: 600;
                    }
                }
            }
        }

        .linksDescription {
            min-height: 300px;
            padding: 4px 40px;

            .itemDesc {
                font-size: 20px;

                .about {
                    line-height: 30px;

                    &:first-child {
                        margin-bottom: 30px;
                    }
                }
            }

            .visionMission {
                display: grid;
                grid-template-columns: 1fr 1fr;
                align-items: start;
                padding: 40px 20px;
                background: $secondaryBgColor;
                border-radius: 8px;
                grid-gap: 20px;

                >div {
                    display: flex;
                    padding-right: 24px;
                    height: 100%;

                    &:first-child {
                        border-right: 1px solid $secondaryColor;

                        @include lg {
                            border-right: unset;
                            border-bottom: 1px solid $secondaryColor;
                        }
                    }

                    .icon {
                        margin-right: 20px;
                        display: flex;
                        align-items: center;
                    }

                    .ietmVision {

                        >p {
                            font-size: 18px;
                            color: $darkGrey;
                            font-weight: 900;
                            text-transform: uppercase;
                            margin-bottom: 10px;
                        }

                        >div {
                            font-size: 14px;
                            line-height: 28px;
                        }
                    }

                    @include lg {
                        padding-right: unset;
                        padding-bottom: 24px;
                    }
                }

                @include lg {
                    grid-template-columns: 1fr;
                }
            }
        }

        @include md {
            grid-template-columns: 1fr;
        }
    }

    @include md {
        min-height: calc(100vh - 383px);
    }
}

body[dir="rtl"] {
    .aboutUsPage {
        .body {
            .linksDescription {
                .visionMission {
                    >div {
                        padding-left: 24px;
                        padding-right: unset;

                        &:first-child {
                            border-right: unset;
                            border-left: 1px solid $secondaryColor;
                        }
                    }
                }
            }
        }
    }
}
@import "../../assets/scss/mixins";
@import "../../assets/scss/variables";

.adminBtn {
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        transition: 0.3s all ease-in;
        cursor: pointer;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        border-radius: 6px;
        font-size: 14px;
        padding: 6px 16px;
        min-width: 64px;
        box-shadow: 0 8px 16px 0 rgba(32, 101, 209, 0.24);

        &.contained {
            &.primary {
                border: 1px solid transparent;
                color: $white;
                background-color: $adminColor;

                &:hover {
                    opacity: 0.8;
                }
            }

            &.dark {
                border: 1px solid transparent;
                color: $white;
                background-color: $black;

                &:hover {
                    opacity: 0.7;
                }
            }

            &.light {
                border: 1px solid transparent;
                color: $black;
                background-color: $white;

                &:hover {
                    opacity: 0.7;
                }
            }

            &.success {
                border: 1px solid transparent;
                color: $white;
                background-color: $successColor;

                &:hover {
                    opacity: 0.7;
                }
            }

            &.error {
                border: 1px solid transparent;
                color: $white;
                background-color: $errorColor;

                &:hover {
                    opacity: 0.7;
                }
            }
        }

        &.outlined {
            &.primary {
                border: 1px solid $adminColor;
                color: $adminColor;
                background-color: transparent;

                &:hover {
                    opacity: 0.7;
                }
            }

            &.dark {
                border: 1px solid $black;
                color: $black;
                background-color: transparent;

                &:hover {
                    opacity: 0.7;
                }
            }

            &.light {
                border: 1px solid $white;
                color: $adminColor;
                background-color: transparent;

                &:hover {
                    opacity: 0.7;
                }
            }

            &.success {
                border: 1px solid $successColor;
                color: $successColor;
                background-color: transparent;

                &:hover {
                    opacity: 0.7;
                }
            }

            &.error {
                border: 1px solid $errorColor;
                color: $errorColor;
                background-color: transparent;

                &:hover {
                    opacity: 0.7;
                }
            }
        }

        &:disabled {
            opacity: 0.3;
            pointer-events: none;
        }
    }
}
@import "../../assets/scss/mixins";
@import "../../assets/scss/variables";
 .message {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-height: calc(100vh - 400px);

        >p {
            max-width: 550px;
            font-size: 20px;
            text-align: center;
            margin-top: 16px;
        }

        >.successMessage {
            color: $successColor;
        }

        >.warningMessage {
            color: $errorColor;
        }

        >button {
            margin-top: 10px;
        }
 }
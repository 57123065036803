@import '../../assets/scss/mixins';
@import '../../assets/scss/variables';

.inputFieldWrapper {
    width: 100%;

    label {
        display: flex;
        align-items: center;
        color: $black;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        .required {
            color: $errorColor;
        }
    }

    .inputContainer {
        position: relative;

        &.adminInput {
            input {
                border-bottom: 1px solid $adminColor;
            }
        }

        input {
            width: 100%;
            min-width: 50px;
            padding: 10px;
            color: $black;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            border: none;
            border-bottom: 1px solid $primaryColor;
            border-radius: 0;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            &::placeholder {
                color: $inputPlaceholderColor;
            }

            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
        }

        .required {
            position: absolute;
            color: $errorColor;
            top: 0;
            right: 0;
        }

        .inputIcon {
            position: absolute;
            right: 10px;
            top: 5px;
            cursor: pointer;
        }
    }
}
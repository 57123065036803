@import "../../../assets/scss/mixins";
@import "../../../assets/scss/variables";

.otpContainer {
    display: flex;
    flex-direction: column;
    max-width: 550px;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    >.otpHeader {
        width: 100%;
        margin-bottom: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        >h2 {
            color: $primaryColor;
            margin-bottom: 15px;
            font-size: 28px;
        }

        >div {
            font-size: 14px;

            span {
                font-weight: 700;
                color: $primaryColor;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    >.otpInputs {
        width: 100%;
        margin-bottom: 25px;
        display: flex;
        align-items: center;

        >div {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            >input {
                border: 2px solid $black;
                border-radius: 4px;
                width: 70px;
                height: 60px;
                text-align: center;
                font-size: 18px;
                font-weight: 700;
            }
        }
    }

    >.otpTimer {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    >.otpButtons {
        width: 100%;
        display: flex;
        align-items: center;

        >div {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .error {
        color: $errorColor;
        padding-top: 4px;
        font-size: 12px;
        height: 20px;
    }
}

@include xs {
    .otpContainer {
        >.otpInputs {
            >div {
                >input {
                  width: 50px;
                  height: 50px;
                }
            }
        }
    }
}
@import '../../../assets/scss/mixins';
@import '../../../assets/scss/variables';

.signInPageGrid {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: $secondaryColor;
    padding: 20px;

    .signInSection {
        background: $white;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.05);
        display: grid;
        grid-template-columns: 1fr 1fr;
        border-radius: 8px;

        .logo {
            display: flex;
            justify-content: center;
            align-items: center;

            >img {
                width: 500px;
                height: 500px;
                object-fit: cover;
                border-radius: 8px 0 0 8px;

                @include lg {
                    width: 400px;
                    height: 400px;
                }

                @include md {
                    width: 500px;
                    height: 230px;
                }

                @include sm {
                    width: 400px;
                }
            }

            @include xs {
                display: none;
            }
        }

        .loginFormContainer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 30px;

            .title {
                font-size: 30px;
                font-weight: 700;
                letter-spacing: 2px;
                text-align: center;
            }

            .form {
                margin-top: 24px;
                display: grid;
                grid-gap: 30px;
            }

            .signInBtn {
                display: flex;
                justify-content: flex-end;

                >button {
                    @include xs {
                        width: 100%;
                    }
                }
            }

            @include md {
                padding: 20px 30px;
            }

            @include xs {
                padding: 40px 30px;
            }
        }

        @include md {
            grid-template-columns: 1fr;
        }

        @include xs {
            width: 100%;
        }
    }
}
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/variables";

.files {
    min-width: 300px;
    margin-top: 10px;
    
    >p {
        font-size: 12px;
    }

    .fileUpload {
        display: flex;
        grid-gap: 10px;

        .fileInput {
            background-color: $white;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            padding: 7px 10px;
            margin-right: 5px;
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            transition: 0.3s all ease-in;
            cursor: pointer;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            border-radius: 6px;
            font-size: 12px;
            padding: 4px 12px;
            min-width: 64px;
            box-shadow: 0 8px 16px 0 rgba(32, 101, 209, 0.24);
            border: 1px solid transparent;
            color: $white;
            background-color: $adminColor;

            &:hover {
                opacity: 0.8;
            }
        }
    }

    .searchInput {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 10px;

        input {
            width: 100%;
            padding: 7px 5px;
            margin-right: 5px;
        }

        .searchInputButton {
            border-radius: 6px;
            border: 1px solid $black;
            padding: 8px;
        }
    }
}
@import '_variables.scss';

@mixin xxs {
  @media (max-width: #{$screen-xxs-max}) {
    @content;
  }
}

@mixin xs {
  @media (max-width: #{$screen-xs-max}) {
    @content;
  }
}

@mixin sm {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}

@mixin md {
  @media (max-width: #{$screen-md-max}) {
    @content;
  }
}

@mixin lg {
  @media (max-width: #{$screen-lg-max}) {
    @content;
  }
}

@mixin xl {
  @media (max-width: #{$screen-xl-max}) {
    @content;
  }
}

@mixin xxl {
  @media (max-width: #{$screen-xxl-max}) {
    @content;
  }
}
@import "../../assets/scss/mixins";
@import "../../assets/scss/variables";

button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transition: 0.3s all ease-in;
    cursor: pointer;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    border-radius: 50px;

    &.contained {
        &.primary {
            border: 1px solid transparent;
            color: $white;
            background-color: $primaryColor;

            &:hover {
                opacity: 0.7;
            }
        }

        &.dark {
            border: 1px solid transparent;
            color: $white;
            background-color: $black;

            &:hover {
                opacity: 0.7;
            }
        }

        &.light {
            border: 1px solid transparent;
            color: $black;
            background-color: $white;

            &:hover {
                opacity: 0.7;
            }
        }

        &.success {
            border: 1px solid transparent;
            color: $white;
            background-color: $successColor;

            &:hover {
                opacity: 0.7;
            }
        }

        &.error {
            border: 1px solid transparent;
            color: $white;
            background-color: $errorColor;

            &:hover {
                opacity: 0.7;
            }
        }

        &.warning {
            border: 1px solid transparent;
            color: $white;
            background-color: $warningColor;

            &:hover {
                opacity: 0.7;
            }
        }

        &.info {
            border: 1px solid transparent;
            color: $white;
            background-color: $infoColor;

            &:hover {
                opacity: 0.7;
            }
        }
    }

    &.outlined {
        &.primary {
            border: 1px solid $primaryColor;
            color: $primaryColor;
            background-color: transparent;

            &:hover {
                opacity: 0.7;
            }
        }

        &.dark {
            border: 1px solid $black;
            color: $black;
            background-color: transparent;

            &:hover {
                opacity: 0.7;
            }
        }

        &.light {
            border: 1px solid $white;
            color: $white;
            background-color: transparent;

            &:hover {
                opacity: 0.7;
            }
        }

        &.success {
            border: 1px solid $successColor;
            color: $successColor;
            background-color: transparent;

            &:hover {
                opacity: 0.7;
            }
        }

        &.error {
            border: 1px solid $errorColor;
            color: $errorColor;
            background-color: transparent;

            &:hover {
                opacity: 0.7;
            }
        }

        &.warning {
            border: 1px solid $warningColor;
            color: $warningColor;
            background-color: transparent;

            &:hover {
                opacity: 0.7;
            }
        }

        &.info {
            border: 1px solid $infoColor;
            color: $infoColor;
            background-color: transparent;

            &:hover {
                opacity: 0.7;
            }
        }
    }

    &.small {
        font-size: 14px;
        padding: 10px 14px;

        @include xs {
            font-size: 12px;
            padding: 8px 12px;
        }
    }

    &.medium {
        font-size: 16px;
        padding: 12px 20px;

        @include xs {
            font-size: 14px;
            padding: 10px 18px;
        }
    }

    &.large {
        font-size: 18px;
        padding: 16px 24px;

        @include xs {
            font-size: 16px;
            padding: 14px 22px;
        }
    }

    &:disabled {
        opacity: 0.3;
        pointer-events: none;
    }
}
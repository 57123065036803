@import '../../assets/scss/mixins';
@import '../../assets/scss/variables';

.tabs {
    display: flex;
    align-items: center;
    border-radius: 0 4px 4px 0;

    >div {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 18px;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        border-bottom: 2px solid transparent;
        transition: color 0.3s ease-in, border-bottom 0.3s ease-in;

        &:not(:last-child) {
            margin-right: 8px;
        }

        &.active {
            color: $primaryColor;
            border-bottom: 2px solid $primaryColor;
        }

        &:hover {
            border-bottom: 2px solid $primaryColor;
            transition: border-bottom 0.3s ease-in;
        }

        @include sm {
            width: 50%;
        }
    }

    @include sm {
        width: 100%;
        justify-content: space-between;
    }
}
@import '../../../../assets/scss/mixins';
@import '../../../../assets/scss/variables';

.eventsNewsContent {
    width: 768px;
    display: grid;
    grid-gap: 40px;
    margin-bottom: 20px;

    .fieldForm {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;

        .inputField {
            .textareaLabel {
                display: flex;
                align-items: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

                >span {
                    margin-left: 4px;
                    color: $errorColor;
                }
            }

            >textarea {
                resize: none;
                width: 100%;
                min-height: 120px;
                padding: 10px;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                border: none;
                border-bottom: 1px solid $adminColor;
            }
        }


        @include lg {
            grid-template-columns: 1fr;
        }
    }

    .attachment {
        padding-bottom: 4px;
        border-bottom: 1px solid $adminColor;

        .uploadFilesContent {
            >button {
                border: none;
                padding: 10px;
                border-radius: 6px;
                background: $adminSecondaryColor;

                >span {
                    color: $errorColor;
                }

                @include xxs {
                    width: 100%;
                }
            }

            .socialImage {
                margin-top: 10px;

                &.bgImage {
                    background: $adminSecondaryColor;
                }

                .stringImages {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    grid-gap: 10px;
                }

                div {
                    position: relative;
                    padding: 6px 8px;

                    >img {
                        max-height: 120px;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    >video {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    .removeAttachment {
                        position: absolute;
                        top: -8px;
                        right: -3px;
                        color: $errorColor;
                        cursor: pointer;
                    }
                }
            }
        }

        >input {
            display: none;
        }
    }
}
@import '../../../assets/scss/mixins';
@import '../../../assets/scss/variables';

.menuOption {
    width: 150px;
    position: absolute;
    top: 57px;
    right: 18px;
    background: $white;
    border-radius: 6px;
    z-index: 12;
    box-shadow: 0px 5px 5px -3px rgba(145, 158, 171, 0.2), 0px 8px 10px 1px rgba(145, 158, 171, 0.14), 0px 3px 14px 2px rgba(145, 158, 171, 0.12);

    .optionContent {
        .header {
            border-bottom: 1px solid $borderColor;
            padding: 12px 16px;
        }

        .options {
            padding: 8px 10px;
            display: grid;
            grid-gap: 8px;

            >a {
                font-size: 14px;
                font-weight: 500;
                padding: 8px;
                border-radius: 6px;

                &:hover {
                    background-color: $borderColor;
                }
            }
        }

        .footer {
            display: grid;
            padding: 8px 10px;
            border-top: 1px solid $borderColor;

            >span {
                font-size: 14px;
                font-weight: 500;
                padding: 8px;
                border-radius: 6px;
                cursor: pointer;

                &:hover {
                    background-color: $borderColor;
                }
            }

        }
    }
}
@import '../../../assets/scss/mixins';
@import '../../../assets/scss/variables';

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);

    .modalContent {
        position: relative;
        background: $secondaryBgColor;
        padding: 20px;
        border-radius: 8px;

        .text {
            color: $darkGrey;
            font-weight: 600;
        }

        .confirmActions {
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            button {
                &:first-child {
                    margin-right: 10px;
                }
            }
        }
    }
}
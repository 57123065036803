@import '../../assets/scss/mixins';
@import '../../assets/scss/variables';


.eventCard {
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-gap: 20px;

    &.isShowEventCard {
        @include lg {
            background: $white;
        }

        .cardImg {
            >img {
                @include sm {
                    height: 220px;
                }
            }

            .imgDate {
                @include lg {
                    bottom: 0;
                }
            }
        }

        .cardInfo {
            .cardBody {
                @include lg {
                    font-weight: 500;
                }
            }
        }
    }

    .cardImg {
        position: relative;

        >img {
            width: 200px;
            height: 200px;
            object-fit: cover;

            @include lg {
                width: 100%;
                height: 180px;
            }

            @include md {
                height: 160px;
            }
        }

        .imgDate {
            width: 100%;
            position: absolute;
            bottom: 4px;
            left: 0;
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            padding: 8px;
            background-color: #212b36cc;
        }
    }

    .cardInfo {
        display: grid;
        grid-gap: 20px;
        padding: 20px 8px 16px 0;

        &.isShowInfo {
            padding-top: 0;
            padding-bottom: 0;

            @include lg {
                padding: 10px 20px;
            }
        }

        .cardDate {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 180px;
            padding: 6px;
            border-radius: 30px;
            background: #FFE0E2;
            color: #525252;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 21px;

            @include lg {
                font-size: 14px;
            }

            @include md {
                font-size: 12px;
                font-weight: 600;
                max-width: 130px;
                line-height: 10px;
            }
        }

        .cardTitle {
            color: $darkGrey;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 29px;

            @include lg {
                font-size: 18px;
                line-height: 24px;
                font-weight: 600;
            }

            @include md {
                font-size: 16px;
            }
        }

        .cardBody {
            color: $darkGrey;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 29px;

            @include lg {
                font-size: 18px;
                line-height: 24px;
                font-weight: 600;
            }

            @include md {
                font-size: 16px;
            }
        }

        .readMore {
            >span {
                color: $darkGrey;
                font-size: 18px;
                font-style: normal;
                font-weight: 800;
                line-height: 23px;
                text-decoration-line: underline;
                margin-right: 4px;
                cursor: pointer;

                &:hover {
                    color: $primaryColor;

                    +svg {
                        path {
                            fill: $primaryColor
                        }
                    }
                }

                @include lg {
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 700;
                }

                @include md {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }

        @include lg {
            grid-gap: 10px;
            padding: 10px 4px 10px 0;
        }

        @include md {
            grid-gap: 4px;
        }

        @include sm {
            padding: 0;
        }
    }

    @include lg {
        grid-template-columns: 1fr;
        grid-gap: 16px;
    }
}
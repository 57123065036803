@import '../../assets/scss/mixins';
@import '../../assets/scss/variables';

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);

    .modalContent {
        position: relative;
        background: $secondaryBgColor;
        padding: 20px;
        border-radius: 8px;
        overflow-y: auto;
        max-height: 700px;
        max-width: 500px;
        width: 100%;

        .modalHeader {
            position: sticky;
            top: 0px;
            right: 0;
            display: flex;
            justify-content: flex-end;

            .closeButton {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                border-radius: 50px;
                background: $white;
                color: $primaryColor;
                font-size: 28px;
                cursor: pointer;
            }
        }

        .modalFooter {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            button {
                &:first-child {
                    margin-right: 20px;
                }
            }
        }
    }
}
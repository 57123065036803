@import '../../assets//scss/mixins';
@import '../../assets//scss/variables';

.newsCardBody {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-gap: 24px;

    &.isShowCard {
        background: $white;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;


        .cardImg {
            >img {
                max-height: 240px;

                @include sm {
                    max-width: 100%;
                }
            }
        }

        .cardInfo {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 0 10px 20px;

            .cardBody {
                font-weight: 500;
            }
        }
    }


    .cardImg {
        position: relative;

        >img {
            max-height: 290px;
            width: 100%;
            height: 100%;
            object-fit: cover;

            @include xs {
                max-width: 428px;
                max-height: 190px;
            }
        }

        .imgDate {
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            padding: 10px;
            background-color: #212b36cc;
        }
    }

    .cardInfo {
        .cardBody {
            color: $darkGrey;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 29px;
            margin-top: 10px;

            @include lg {
                font-size: 18px;
                line-height: 24px;
                font-weight: 600;
            }

            @include md {
                font-size: 16px;
            }
        }

        .cardDate {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 180px;
            padding: 6px;
            border-radius: 30px;
            background: #FFE0E2;
            color: #525252;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 21px;

            @include md {
                font-size: 14px;
                font-weight: 600;
                line-height: 18px;
            }

            @include xs {
                font-size: 12px;
                max-width: 130px;
            }
        }

        .cardTitle {
            color: $darkGrey;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 29px;
            margin-top: 10px;

            @include lg {
                font-size: 20px;
                line-height: 24px;
            }

            @include md {
                font-size: 18px;
            }

            @include xs {
                font-size: 16px;
                line-height: 20px;
            }
        }

        .btnMore {
            margin-top: 10px;
            color: $primaryColor;
            font-weight: 600;

            >span {
                cursor: pointer;
            }
        }
    }

    @include xs {
        grid-gap: 16px;
    }

    @include xl {
        grid-template-rows: 200px auto;
    }
}
$screen-xxs-max: 380px;
$screen-xs-max: 480px;
$screen-sm-max: 600px;
$screen-md-max: 768px;
$screen-lg-max: 1024px;
$screen-xl-max: 1200px;
$screen-xxl-max: 1280px;

$containerWidth: 1296px;

$fontFamily: 'Montserrat', sans-serif;
$fontUrbanist: 'Urbanist', sans-serif;

$white: #FFFFFF;
$lightGray: #E6E6E6;
$black: #000000;
$darkGrey: #333333;
$darkGreyHover: #575757;
$primaryColor: #EC1C2B;
$secondaryColor: #D9D9D9;
$inputPlaceholderColor: rgba(0, 0, 0, 0.56);
$successColor: #31a113;
$errorColor: #e52020;
$warningColor: #ebda28;
$infoColor: #42a5f5;
$bgColor: #FAFAFA;
$secondaryBgColor: #F7F7F7;
$borderColor: #D3D1D1;
$tabBgColor: #e6eef9;

$adminPrimaryColor: #279AFB;
$adminColor: #2065D1;
$adminSecondaryColor: #E6F6FF;

$checkboxSize: 24px;
$radioSize: 18px;
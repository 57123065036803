@import '../../../../assets/scss/mixins';
@import '../../../../assets/scss/variables';

.tableContainer {
    width: 100%;
    
    .topActions {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 20px;

        .inputsContainer {
            max-width: 500px;
            .searchInput {
                width: 100%;
                display: flex;
                align-items: center;
                margin-top: 10px;

                input {
                    width: 100%;
                    padding: 7px 5px;
                    margin-right: 5px;
                }
                .searchInputButton {
                    border-radius: 6px;
                    border: 1px solid $black;
                    padding: 8px;
                }
            }
        }

    }
    
    .body {
        width: 100%;
        overflow: hidden;
        overflow-x: scroll;
        border-radius: 20px;

        > table {
            width: 100%;
            border-collapse: collapse;
            text-align: center;

            ul {
                list-style-type: disc;
                list-style-position: inside;
                text-align: left;
            }

            tr {
                &:nth-child(even) {
                    background-color: $secondaryBgColor;
                }
    
                &:hover {
                    background-color: $tabBgColor;
                }

                td {
                    padding: 8px;
                    border: 1px solid #ddd;
                    text-align: center;
                }
        
                th {
                    padding: 10px 20px;
                    text-align: left;
                    background-color: $adminColor;
                    color: white;
                    text-align: center;
                }
            }
        }
    }
}

@import '../../../../assets/scss/mixins';
@import '../../../../assets/scss/variables';

.mediaModalContent {
    display: grid;
    grid-gap: 40px;
    margin-bottom: 20px;

    .title {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;

        @include lg {
            grid-template-columns: 1fr;
        }
    }

    .imageField {
        margin-top: 10px;

        .uploadContent {
            >button {
                border: none;
                padding: 10px;
                border-radius: 6px;
                background: $adminSecondaryColor;

                >span {
                    color: $errorColor;
                }

                @include xxs {
                    width: 100%;
                }
            }

            .socialImage {
                margin-top: 12px;

                .image {
                    >img {
                        max-width: 200px;
                        max-height: 120px;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }

        >input {
            display: none;
        }
    }

    .ckEditor {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;

        .editorTitle {
            margin-bottom: 10px;

            color: $darkGrey;
            font-weight: 600;
            line-height: 24px;

            >span {
                color: $errorColor;
                margin-left: 4px;
            }
        }

        @include lg {
            grid-template-columns: 1fr;
        }
    }

    .category {
        .checkboxLable {
            color: $darkGrey;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            >span {
                color: $errorColor;
                margin-left: 4px;
            }
        }

        .checkboxField {
            display: flex;
            align-items: center;
            margin-top: 20px;
            padding: 8px;

            >div {
                &:first-child {
                    margin-right: 20px;
                }
            }

            @include xxs {
                width: 100%;
                justify-content: space-between;
            }
        }
    }

    .publish {
        .checkboxLable {
            color: $darkGrey;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }
}
@import "../../assets/scss/mixins";
@import "../../assets/scss/variables";

.pageNotFound {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 136px);

    .title {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 48px;

        >span {
            width: 3px;
            height: 60px;
            background-color: $darkGrey;
            margin: 0 20px;

            @include md {
                height: 40px;
                margin: 0 10px;
            }

            @include xs {
                height: 30px;
            }
        }

        @include md {
            font-size: 30px;
        }

        @include xs {
            font-size: 22px;
        }
    }

    >a {
        text-align: center;
        margin-top: 16px;

        &:hover {
            color: $primaryColor;
        }
    }

    @include lg {
        height: 100vh;
    }
}
@import '../../assets/scss/mixins';
@import '../../assets/scss/variables';

.eventsNewsPage {
    width: 100%;
    padding: 40px 20px;

    .header {
        .headerContent {
            padding: 10px 20px;

            >h2 {
                font-size: 38px;
                font-style: normal;
                font-weight: 700;
                line-height: 55px;
                text-align: center;

                @include sm {
                    font-size: 30px;
                    line-height: 45px;
                }
            }
        }

        @include sm {
            margin-bottom: 12px;
        }
    }

    .eventsNewsBody {
        >h3 {
            font-size: 28px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            @include sm {
                font-size: 22px;
                line-height: 33px;
            }

            @include xs {
                padding: 0 10px;
            }
        }

        .card {
            margin-top: 28px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 40px;

            @include lg {
                grid-template-columns: repeat(2, 1fr);
            }

            @include sm {
                grid-gap: 24px;
            }

            @include xs {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }

    .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 28px;
    }

    @include xs {
        padding-left: 0;
        padding-right: 0;
    }
}
@import '../../../assets/scss/mixins';
@import '../../../assets/scss/variables';

.adminHeader {
    width: calc(100vw - 240px);
    position: fixed;
    right: 0;
    left: 240px;
    top: 0;
    z-index: 999;
    border-bottom: 1px solid $secondaryColor;

    .headerBody {
        padding: 10px;
        background: $bgColor;
        display: grid;
        grid-template-columns: 1fr 1fr;

        .searchContent {
            display: flex;
            align-items: center;

            .hamburgerMenu {
                display: none;

                .hamburgerIcon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $lightGray;
                    padding: 4px;
                    border-radius: 6px;
                    cursor: pointer;
                }

                @include md {
                    display: flex;
                    margin-right: 12px;
                }
            }
        }

        .accountContent {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 10px;

            .notification {
                position: relative;
                cursor: pointer;
                margin-top: 8px;

                .count {
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: -6px;
                    right: -8px;
                    background: $primaryColor;
                    padding: 3px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    font-size: 12px;
                    color: $white;
                }
            }

            .avatar {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                cursor: pointer;
                margin-left: 28px;

                >img {
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    object-fit: cover;
                    color: transparent;
                    text-indent: 10000px;
                    border-radius: 50%;
                }
            }
        }
    }

    &.closed {
        width: 100%;
        left: 0;
    }
}
@import '../../assets/scss/mixins';
@import '../../assets/scss/variables';

.formCheckbox {
  position: relative;
  display: block;

  .checkbox {
    position: absolute;
    opacity: 0;

    &.adminCheckbox {
      &:checked {
        &+label {

          &:after {
            border-color: $adminColor;
          }
        }
      }
    }

    &+label {
      display: inline-block;
      position: relative;
      cursor: pointer;
      line-height: $checkboxSize;
      padding-left: $checkboxSize + 10;
      color: $black;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;

      &:before {
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        content: "";
        background: transparent;
        width: $checkboxSize;
        height: $checkboxSize;
        box-shadow: inset 0 0 0 1px $inputPlaceholderColor;
        border-radius: 4px;
        transition: 200ms ease-in-out all;
      }

      &:after {
        position: absolute;
        display: block;
        top: 1px;
        left: 8px;
        content: "";
        width: 8px;
        height: 14px;
        border-right: 2px solid transparent;
        border-bottom: 2px solid transparent;
        transform: rotate(45deg);
        transition: 200ms ease-in-out all;
      }

      @include sm {
        font-size: 14px;
      }
    }

    &+label:focus {
      outline: none;
    }

    &:checked {
      &+label {

        &:after {
          border-color: $primaryColor;
        }
      }
    }

    &:disabled {
      &+label {
        color: #cecece;

        &:before {
          background: #f2f2f2;
          box-shadow: none;
        }

        &:after {
          border-color: transparent;
        }
      }
    }

    &:checked:disabled {
      &+label {
        color: #cecece;

        &:before {
          background: #f2f2f2;
          box-shadow: none;
        }

        &:after {
          border-color: #cecece;
        }
      }
    }
  }
}
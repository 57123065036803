@import '../../assets/scss/mixins';
@import '../../assets/scss/variables';

.emptyData {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 2em;
    height: calc(100vh);

    >img {
        max-width: 600px;
        max-height: 600px;

        @include sm {
            max-width: 370px;
            max-height: 370px;
        }
    }
}
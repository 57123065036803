@import "../../assets/scss/mixins";
@import "../../assets/scss/variables";

.joinUsPageGrid {
    max-width: 800px;
    padding: 20px;
    display: flex;
    justify-content: center;

    .successMessage {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-height: calc(100vh - 400px);

        .container {
            border: 2px solid $secondaryColor;
            border-radius: 10px;
            padding: 40px;
            display: flex;
            flex-direction: column;
            grid-gap: 20px;

            >h2 {
                color: $primaryColor;
                font-weight: 600;
            }

            >.benefits {
                >p {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 10px;
                }

                >ul {
                    list-style-type: disc;
                    list-style-position: inside;
                }
            }

            >.whatsappLink {
                >a {
                    font-weight: 700;
                    color: $primaryColor;
                }
            }
        }
    }

    .joinUsFormContainer {
        max-width: 600px;
        min-height: 500px;
        width: 100%;
        border: 1px solid $secondaryColor;
        padding: 20px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        grid-gap: 15px;

        >h2 {
            color: $primaryColor;
            font-size: 50px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 20px;
            text-align: center;

            @include md {
                font-size: 36px;
                margin-bottom: 40px;
            }

            @include sm {
                font-size: 30px;
            }
        }

        .subTitle {
            margin-bottom: 30px;
            font-size: 14px;
            color: $darkGrey;
        }

        .form {
            .sectionTitle {
                margin-bottom: 20px;
                font-size: 26px;

                @include md {
                    font-size: 24px;
                }

                @include sm {
                    font-size: 22px;
                }
            }

            .companyDetails {
                margin-top: 50px;

                .licenseDetails {
                    margin-top: 50px;
                }

                .cardDetails {
                    >p {
                        margin: 40px 0px 20px 0px;
                        font-size: 18px;
                    }

                    .martialStatus {
                        margin-top: 20px;
                    }
                }
            }

            .constactDetails {
                margin-top: 50px;
            }

            .information {
                margin-top: 50px;
                display: flex;

                .text {
                    font-weight: 600;
                    color: $darkGrey;
                    margin-top: 12px;
                }
            }

            .loginSectionContent {
                grid-template-columns: 1fr !important;
            }

            .sectionContent {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 40px;
                align-items: center;

                .inputField {
                    position: relative;
                    width: 100%;

                    >div {
                        width: 100%;
                    }

                    &.fullWidth {
                        grid-column: 1/3;

                        @include xs {
                            grid-column: 1/2;
                        }
                    }

                    .dateTitle {
                        display: flex;
                        align-items: center;
                        color: $black;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 29px;

                        .required {
                            color: $errorColor;
                        }
                    }

                    .error {
                        position: absolute;
                        bottom: -24px;
                        color: $errorColor;
                        padding-top: 4px;
                        font-size: 12px;
                        height: 20px;
                    }

                    .birthDate {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        grid-gap: 60px;
                        margin-top: 10px;
                    }

                    .checkboxTitle {
                        display: flex;
                        align-items: center;
                        color: $black;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;

                        .required {
                            color: $errorColor;
                            margin-left: 4px;
                        }
                    }

                    .checkboxField {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        padding: 10px;
                    }
                }


                @include xs {
                    grid-template-columns: 1fr;
                }
            }

            .inputRadioField {
                width: 100%;

                .radioTitle {
                    display: flex;
                    align-items: center;
                    color: $black;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;

                    .required {
                        color: $errorColor;
                        margin-left: 4px;
                    }
                }

                .radioField {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    padding: 10px;
                }
            }

            .multipleContribution {
                >div {
                    display: flex;

                    .text {
                        margin-top: 18px;
                        color: $darkGrey;
                    }
                }
            }

            .upload {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 28px 0;

                .uploadFile {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }

                >input {
                    display: none;
                }

                .upladedFiles {
                    position: relative;

                    img {
                        max-width: 180px;
                        max-height: 80px;
                        object-fit: cover;
                    }

                    .file {
                        .removeFile {
                            position: absolute;
                            right: -10px;
                            top: -10px;
                            color: #EC1C2B;
                            cursor: pointer;
                        }
                    }
                }
            }

            .signupBtn {
                display: flex;
                justify-content: flex-end;
                grid-column: 1/3;
                margin-top: 28px;

                button {
                    @include sm {
                        width: 100%;
                    }
                }

                @include lg {
                    grid-column: 1;
                }

                @include xs {
                    justify-content: center;
                }
            }

            @include lg {
                grid-template-columns: 1fr;
            }
        }

        .nextBtn {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-top: 28px;

            &.btnStyle {
                justify-content: space-between;
            }
        }
    }

    @include md {
        padding: 20px 40px;
    }

    @include lg {
        padding: 100px 20px 20px;
    }
}

body[dir="rtl"] {
    .joinUsPageGrid {
        .joinUsFormContainer {
            .form {
                .information {
                    .text {
                        margin-right: 16px;
                    }
                }
            }
        }
    }
}
@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixins';

.newsletterContent {
   .content {
    margin: 0 0 20px 0;

    >div {
      max-width: 700px;
    }

  }
}

.dateTimePicker {
  width: 100%;
  margin: 20px 0;
  
  >div {
    background-color: white;
    padding: 5px 0;
    border: none;
    border-bottom: 1px solid $adminColor;
  }
}
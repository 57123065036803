@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.searchResult {
    padding: 40px 20px;

    .searchField {
        margin: 60px 0;
    }

    .searchContent {
        width: 100%;
        display: grid;
        grid-gap: 40px;

        .result {
            border-bottom: 1px solid $primaryColor;
            padding-bottom: 30px;

            >h3 {
                color: $primaryColor;
                margin-bottom: 20px;

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }

        .noResults {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            padding: 40px;
        }

        .pagination {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    @include lg {
        padding: 100px 20px 40px;
    }
}
@import './assets/scss/mixins';
@import './assets/scss/variables';

@font-face {
  font-family: 'GESSTwoMedium';
  src: url('./assets/fonts/ge-ss-two-medium.otf') format('opentype');
}

@font-face {
  font-family: 'GESSUniqueBold';
  src: url('./assets/fonts/ge-ss-unique-bold.otf') format('opentype');
}

@font-face {
  font-family: 'GESSUniqueLight';
  src: url('./assets/fonts/ge-ss-unique-light.otf') format('opentype');
}

@font-face {
  font-family: 'Gothic';
  src: url('./assets/fonts/GOTHIC.eot');
  src:
    url('./assets/fonts/GOTHIC.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/GOTHIC.woff') format('woff'),
    url('./assets/fonts/GOTHIC.TTF') format('truetype'),
    url('./assets/fonts/GOTHIC.svg#Gothic') format('svg');
}

@font-face {
  font-family: 'GothicBold';
  src: url('./assets/fonts/GOTHICB.TTF') format('truetype');
}

@font-face {
  font-family: 'JannaLTBold';
  src: url('./assets/fonts/Janna LT Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'JannaLTRegular';
  src: url('./assets/fonts/Janna LT Regular.ttf') format('truetype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background: $darkGrey
}

::-webkit-scrollbar-thumb:hover {
  background: $darkGreyHover
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-track-piece:end {
  background: #f1f1f1;
}

::-webkit-scrollbar-track-piece:start {
  background: #f1f1f1;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.container {
  position: relative;
  // z-index: 999;
  max-width: $containerWidth;
  margin: 0 auto;
}

// .body-content {
//   min-height: calc(100vh - 400px);
//   margin-bottom: 30px;
// }

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}

table {
  border-collapse: collapse;
}

.media-swiper-container {
  .swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .swiper-slide {
    background-size: cover;
    background-position: center;
  }

  .mySwiper2 {
    height: 600px;
    width: 100%;

    .swiper-button-next,
    .swiper-button-prev {
      color: $primaryColor;

      @include sm {
        display: none;
      }
    }

    video,
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include md {
      height: 400px;
    }

    @include xs {
      height: 220px;
    }
  }

  .mySwiper {
    max-width: 600px;
    height: 130px;
    box-sizing: border-box;
    padding: 10px 0;

    .swiper-wrapper {
      justify-content: center;
    }

    video,
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include sm {
      max-width: 350px;
      height: 85px;
    }
  }

  .mySwiper .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }

  .mySwiper .swiper-slide-thumb-active {
    opacity: 1;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  background: transparent;
  overflow: hidden;
}

.swiper-pagination-bullet-active {
  background: $primaryColor !important;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
}

.arrow-right {
  right: -28px;
  z-index: 999;

  svg {
    circle {
      fill: #FFDBDD;
    }

    @include sm {
      width: 40px;
      height: 40px;
    }
  }
}

.arrow-left {
  left: -28px;
  z-index: 999;

  svg {
    circle {
      fill: #FFDBDD;
    }

    @include sm {
      width: 40px;
      height: 40px;
    }
  }
}

.swiper-button-disabled {
  opacity: 1 !important;

  svg {
    circle {
      fill: #E4E4E4;
    }

    @include sm {
      width: 40px;
      height: 40px;
    }
  }
}

.ck.ck-editor {

  @include xl {
    max-width: 400px !important;
  }

  @include lg {
    max-width: 100% !important;
  }

  @include xs {
    max-width: 400px !important;
  }

  @include xxs {
    max-width: 330px !important;
  }
}

.ck.ck-editor__main>.ck-editor__editable {
  min-height: 200px !important;

  @include sm {
    min-height: 150px !important;
  }

  @include xs {
    min-height: 120px !important;
  }
}

.dashboard-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 120px 20px 20px 260px;

  @include lg {
    padding: 100px 20px 20px 260px;
  }

  @include md {
    padding: 100px 20px 20px;
  }
}

.react-tel-input {
  .form-control {
    width: 100% !important;
    border-radius: unset !important;
    border: none !important;
    border-bottom: 1px solid $primaryColor !important;
    // max-width: 255px !important;
    padding-left: 40px !important;

    @include xs {
      max-width: unset !important;
    }
  }

  .flag-dropdown {
    border: none !important;
    background-color: transparent !important;
  }

  .selected-flag {
    padding: 0 !important;

    &.open {
      background: transparent !important;
    }

    &:hover {
      background-color: transparent !important;
    }
  }

  .country-list {
    max-width: 255px !important;
    margin: 0 !important;
    border: 1px solid $primaryColor;
    border-top: none !important;
    border-radius: 0 0 4px 4px !important;
  }
}

.englishHeader {
  font-family: 'Gothic', sans-serif;
}

.arabicHeader {
  font-family: 'JannaLTRegular', sans-serif;
}

.englishContent {
  font-family: 'Gothic', sans-serif;
}

.arabicContent {
  font-family: 'GESSTwoMedium', sans-serif;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  background-color: #ffffff;
  // box-shadow: 0 1px 2px 0 #c9ced1;

  >li {
    margin: 10px;
    cursor: pointer;

    &:hover {
      color: $adminColor;
    }
  }

  .prev,
  .next {
    &.disabled {
      >a {
        cursor: not-allowed;

        >svg {
          path {
            stroke: #2065d18f;
          }
        }
      }
    }

    >a {
      color: $adminColor;
      font-weight: 700;
    }
  }

  .selected {
    width: 20px;
    background: $adminColor;
    color: $white;
    padding: 3px;
    border-radius: 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;

    >a {
      font-weight: 600;
    }

    &:hover {
      color: $white;
    }
  }
}
.flatpickr-input {
  width: 100%;
  padding: 10px;
  margin: 20px 0;
  border: none;
  border-bottom: 1px solid $adminColor;
  font-size: 16px;
}

body[dir="rtl"] {
  .react-tel-input {
    .form-control {
      padding-left: 0 !important;
      padding-right: 30px !important;
    }

    .selected-flag {
      direction: ltr !important;
      width: unset !important;
      margin-right: 25px !important;
    }
  }
}
@import '../../../../assets/scss/mixins';
@import '../../../../assets/scss/variables';

.tableContainer {
    width: 100%;

    .tableTopActions {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
    }

    .tableBody {
        width: 100%;

        .actions {
            display: flex;
            align-items: center;
            justify-content: center;

            >div {
                cursor: pointer;

                &:first-child {
                    margin-right: 6px;
                }
            }
        }

        >table {
            width: 100%;
        }

        >table,
        th,
        td {
            border: 1px solid black;
            border-collapse: collapse;
            text-align: center;
        }

        th,
        td {
            padding: 4px;
        }
    }
}
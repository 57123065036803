@import '../../assets/scss/variables';

.loading {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999 !important;
    background-color: rgba(33, 43, 54, 0.8);

    .loader {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: inline-block;
        border-top: 3px solid $primaryColor;
        border-right: 3px solid transparent;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
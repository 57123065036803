@import '../../../../assets/scss/mixins';
@import '../../../../assets/scss/variables';

.publicationContent {
    display: grid;
    grid-gap: 20px;

    .title {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;

        @include lg {
            grid-template-columns: 1fr;
        }
    }

    .thumbnailImageField {
        margin-top: 10px;

        .uploadContent {
            >button {
                border: none;
                padding: 10px;
                border-radius: 6px;
                background: $adminSecondaryColor;

                >span {
                    color: $errorColor;
                }

                @include xxs {
                    width: 100%;
                }
            }

            .socialImage {
                margin-top: 12px;

                .image {
                    >img {
                        max-width: 200px;
                        max-height: 120px;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }

        >input {
            display: none;
        }
    }

    .ckEditor {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;

        .editorTitle {
            margin-bottom: 10px;

            color: $darkGrey;
            font-weight: 600;
            line-height: 24px;

            >span {
                color: $errorColor;
                margin-left: 4px;
            }
        }

        @include lg {
            grid-template-columns: 1fr;
        }
    }

    .attachment {
        .uploadFilesContent {
            >button {
                border: none;
                padding: 10px;
                border-radius: 6px;
                background: $adminSecondaryColor;

                @include xxs {
                    width: 100%;
                }
            }

            .socialImage {
                margin-top: 10px;
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
                grid-gap: 10px;

                >div {
                    position: relative;
                    padding: 6px 8px;
                    background: $secondaryBgColor;
                    max-width: 250px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                    border-radius: 0.3rem;

                    .removeAttachment {
                        position: absolute;
                        top: -8px;
                        right: -3px;
                        color: $errorColor;
                        cursor: pointer;
                    }
                }
            }
        }

        >input {
            display: none;
        }
    }

    .category {
        .checkboxLable {
            color: $darkGrey;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            >span {
                color: $errorColor;
                margin-left: 4px;
            }
        }

        .checkboxField {
            display: flex;
            align-items: center;
            margin-top: 20px;
            padding: 8px;

            >div {
                &:first-child {
                    margin-right: 20px;
                }
            }

            @include xxs {
                width: 100%;
                justify-content: space-between;
            }
        }
    }

    .btn {
        display: flex;
        justify-content: flex-end;

        >button {
            @include xs {
                width: 100%;
            }
        }

    }
}
@import '../../../assets/scss/mixins';
@import '../../../assets/scss/variables';

.adminSidebar {
    .sidebar {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        width: 240px;
        height: 100vh;
        border-right: 1px solid $secondaryColor;
        background: $bgColor;
        overflow: hidden;
        transition: all .3s ease-in;
        z-index: 999;


        .sidebarContent {
            margin-top: 61px;

            .header {
                display: flex;
                align-items: center;
                padding: 10px 24px;
                box-shadow: 1px 1px 3px 3px $lightGray;

                .avatar {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;

                    >img {
                        width: 100%;
                        height: 100%;
                        text-align: center;
                        object-fit: cover;
                        color: transparent;
                        text-indent: 10000px;
                        border-radius: 50%;
                    }
                }

                .userInfo {
                    margin-left: 10px;
                    font-size: 14px;
                    font-weight: 600;
                }
            }

            .sidebarBody {
                margin-top: 20px;
                display: grid;
                grid-gap: 10px;

                .links {
                    display: flex;
                    align-items: center;
                    padding: 10px 24px;
                    border-right: 2px solid transparent;
                    font-weight: 500;
                    font-size: 14px;

                    &:hover {
                        background-color: $adminSecondaryColor;
                        color: $adminPrimaryColor;

                        .icon {
                            svg {
                                path {
                                    fill: $adminPrimaryColor;
                                }
                            }
                        }
                    }

                    &.active {
                        background-color: $adminSecondaryColor;
                        border-right: 4px solid $adminPrimaryColor;
                        color: $adminPrimaryColor;
                        box-shadow: 0 1px 8px #0096db94;

                        .icon {
                            svg {
                                path {
                                    fill: $adminPrimaryColor;
                                }
                            }
                        }
                    }

                    .icon {
                        margin-right: 10px;
                    }
                }
            }
        }

        @include md {
            &.closed {
                transform: translateX(-240px);
            }

            &.opened {
                transform: translateX(0);
            }
        }

        @include sm {
            width: 100%;
        }
    }

    &.closed {
        visibility: hidden;
    }

    &.opened {
        visibility: visible;
        background-color: rgba(33, 43, 54, 0.8);
        width: 100vw;
        height: 100vh;
        position: fixed;
        z-index: 99999;
        left: 0;
        top: 0;
        display: flex;
        justify-content: flex-end;
    }
}
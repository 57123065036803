@import '../../../assets/scss/mixins';
@import '../../../assets/scss/variables';

.eventsNewsList {
    width: 100%;

    >h2 {
        color: $darkGrey;
        font-size: 38px;
        font-style: normal;
        font-weight: 600;
        line-height: 50px;
        margin-bottom: 30px;

        @include sm {
            text-align: center;
            font-size: 30px;
            margin-bottom: 20px;
        }

        @include xs {
            font-size: 22px;
        }
    }
}